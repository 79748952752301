.productCard {
  position: relative;
  border: 1px solid #e0e0e0;
  box-shadow: none !important;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: var(--border-color, transparent);
  }

  &.packed {
    background-color: #e8f5e9;
  }

  &.skipped {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.cardContent {
  padding-left: 16px !important;
  padding-right: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.contentContainer {
  display: flex;
  align-items: center;
}

.productInfo {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
  margin-right: 16px;
}

.actionContainer {
  min-width: 65px;
  max-width: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.outOfStock {
  color: #d32f2f;
  font-weight: bold !important;
}
